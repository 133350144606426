<template>
  <div>
    <!-- 新建订单 -->
    <el-drawer title="选择展位" :visible.sync="boothDialogShow" direction="btt">
      <div class="boothContent">
        <div style="margin-bottom:10px;padding-left:10px">
          <p style="margin-bottom:10px">已选择展位：</p>
          <div v-if="boothChooseArr.length" class="tabBox">
            <el-tag
              v-for="(item, i) in boothChooseArr"
              :key="i"
              type="primary"
              closable
              @close="handleClose(item, i)"
            >
              {{ item.booth_no }}
            </el-tag>
          </div>
        </div>
        <div>
          <span style="margin:10px 0px;padding-left:10px">展位类型：</span>
          <el-button
            :type="booty_type == item.id ? 'primary' : ''"
            @click="booty_type_change(item)"
            v-for="(item, i) in boothTypeArr"
            :key="i"
            size="mini"
            :label="item.id"
            >{{ item.name }}</el-button
          >
        </div>
        <div
          class="boothBox"
          v-loading="boothDialogLoading"
          style="min-height:300px"
        >
          <!-- 区列表 -->
          <div class="area">
            <div
              class="areaItem"
              :class="{ areaItem_active: boothActiveIndex == i }"
              v-for="(item, i) in boothAllArr"
              :key="i"
              @click="areaClick(i)"
            >
              {{ item.area }}
            </div>
          </div>
          <!-- 区展位 -->
          <div class="boothConten" v-if="boothAllArr.length">
            <div
              class="boothContenItem "
              :class="{ active: item.checked }"
              v-for="(item, i) in boothAllArr[boothActiveIndex].data_list"
              :key="i"
              @click="handleClick(item)"
            >
              <span
                >{{ item.booth_no }} （{{ item.hbh_booth_type_name }}
                {{ item.price_cny||('$'+item.price_usd) }}元 ）</span
              >
              <span
                class="txtColor el-icon-check"
                style="font-weight: 800"
                v-show="item.checked"
              ></span>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <el-button @click="boothDialogShow = false" style="width:40%"
            >取 消</el-button
          >
          <el-button type="primary" @click="chooseSure" style="width:60%"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      boothDialogShow: false,
      boothDialogLoading: false,
      boothTypeArr: [],
      booty_type: "",
      boothChooseArr: [], // 已选择的数据
      boothAllArr: [],
      boothActiveIndex: 0,
      seaveLoading: false,
    };
  },
  mounted() {
    this.getBoothType();
  },
  methods: {
    // 选择展位初始化
    openInit(boothCheckArr = []) {
      this.booty_type = "all";
      this.boothActiveIndex = 0;
      this.boothDialogShow = true;
      this.boothChooseArr = JSON.parse(JSON.stringify(boothCheckArr));
      this.getAllBooth();
    },
    // 获取展位类型数据
    getBoothType() {
      this.$store.dispatch("booth/boothType", {
        meeting_id:71
      }).then((res) => {
        let obj = {
          id: "all",
          name: "全部",
          parent_id: 0,
        };
        this.boothTypeArr = res.data;
        this.boothTypeArr.unshift(obj);
      });
    },
    // 获取可选展位数据
    async getAllBooth() {
      this.boothDialogLoading = true;
      let params = {
        parent_id: this.booty_type == "all" ? "" : this.booty_type,
        nopage: 1,
        meeting_id:71
      };
      let res = await this.$store.dispatch("booth/boothList", params);
      console.log(res,'sasasa')
      this.boothAllArr = res.data;
      this.boothDialogLoading = false;
      this.couputedIsChecked();
    },

    // 展位类型变化 重新请求展位数据
    booty_type_change(e) {
      this.booty_type = e.id;
      this.boothActiveIndex = 0;
      this.getAllBooth();
    },
    // 区域更换  （展位类型变化 区域变化都要重新计算数据是否已经选中  即执行couputedIsChecked函数）
    areaClick(i) {
      this.boothActiveIndex = i;
      this.couputedIsChecked();
    },
    // 计算已选择的数据
    couputedIsChecked() {
      let existArr = [];
      this.boothChooseArr.forEach((item) => {
        existArr.push(item.id);
      });
      this.boothAllArr[this.boothActiveIndex].data_list.map((item) => {
        if (existArr.indexOf(item.id) != -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
    },
    // 选择 或取消选择
    handleClick(item) {
      let allArr = JSON.parse(JSON.stringify(this.boothAllArr));
      let index = allArr.findIndex((e) => e.area == item.area);
      let i = allArr[index].data_list.findIndex((e) => e.id == item.id);
      if (item.checked == true) {
        // 选中
        let i = this.boothChooseArr.findIndex((el) => el.id == item.id);
        this.boothChooseArr.splice(i, 1);
      } else {
        //  取消选中
        this.boothChooseArr.push(item);
      }
      this.$nextTick(() => {
        allArr[index].data_list[i].checked = !item.checked;
        this.boothAllArr = JSON.parse(JSON.stringify(allArr));
      });
    },
    // 在已选择的数据删除
    handleClose(item, i) {
      this.boothChooseArr.splice(i, 1);
      let arr = this.boothAllArr;
      let index = arr.findIndex((e) => e.area == item.area);
      let k = arr[index].data_list.findIndex((el) => el.id == item.id);
      arr[index].data_list[k].checked = false;
      this.boothAllArr = JSON.parse(JSON.stringify(arr));
    },
    // 展位确定选择
    chooseSure() {
      this.boothDialogShow = false;
      this.$emit("checkBack", {
        check_arr: JSON.parse(JSON.stringify(this.boothChooseArr)),
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-drawer {
  height: auto !important;
}

/deep/ #el-drawer__title {
  margin-bottom: 0px !important;
  padding: 10px;
}
/deep/ .el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

.txtColor {
  color: #409eff;
  cursor: pointer;
}
.boothContent {
  width: 100%;
  margin: auto;
}
.boothBox {
  margin-top: 20px;
  display: flex;
  .area {
    background: #f7f7f7;
    width: 100px;
    .areaItem {
      text-align: center;
      padding: 15px;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    .areaItem_active {
      color: #333333;
      background: #fff;
    }
  }
  .boothConten {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    padding-left: 20px;
    .boothContenItem {
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      cursor: pointer;
    }
    .active {
      color: #4f88f7;
      font-weight: 800 !important;
    }
  }
}

.tabBox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-right: 30px;
}
.btnBox {
  margin-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 20px;
}

.empty {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999;
}
.checkItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f5ff;
  padding: 0 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #4f88f7;
  i {
    cursor: pointer;
  }
}
</style>
